.container {
    background-color: var(--white-100);
    padding: 40px 23px 16px;

    > .header {
        color: var(--black-100);
        font-family: var(--header-2-font-family);
        font-size: 26px;
        font-weight: bold;
    }
}