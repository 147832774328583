.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white-100);

  > .content {
    flex-grow: 1;
    overflow-y: scroll;
  }

  > .menu {
    z-index: 100;
    box-shadow: var(--card-normal);
    position: relative;
  }
}