.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}
  
.item {
    background-color: var(--jaffa-60);
    border-radius: 6px;

    display: flex;
    align-items: center;
    overflow: hidden;

    height: 46px;
}
  
.label {
    color: var(--black-100);
    font-family: var(--body-bold-font-family);
    font-size: var(--body-bold-font-size);
    font-style: var(--body-bold-font-style);
    font-weight: var(--body-bold-font-weight);
}