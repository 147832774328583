.questions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid var(--black-60);
  border-bottom: 1px solid var(--black-60);

  > .description {
    color: var(--black-100);
    font-family: var(--header-2-font-family);
    font-style: var(--header-2-font-style);
  }

  > .updatedDate {
    display: flex;
    flex-direction: column;
    align-items: end;

    > span {
      color: var(--black-80);
      font-size: 10px;
      text-align: right;
    }
  }
}

.tips {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  
  &.hide {
    height: 192px;
    overflow: hidden;
  }

  > .title {
    color: var(--matisse-100);
    font-family: var(--header-2-font-family);
    font-size: var(--header-2-font-size);
    font-style: var(--header-2-font-style);
    font-weight: var(--header-2-font-weight);
  }

  > .body {
    word-wrap: break-word;
    overflow: hidden;
    border-bottom: 5px solid var(--white-100);
  }

  > .overlay {
    position: absolute;
    display: flex;
    align-items: end;
    justify-content: center;
    bottom: 0;
    height: 120px;
    width: 100%;
    gap: 8px;
    box-sizing: border-box;
    background: linear-gradient(transparent 0%, white 60%);

    > span {
      color: var(--black-80);
      font-family: var(--header-2-font-family);
      font-style: var(--header-2-font-style);
      font-weight: var(--header-2-font-weight);
      font-size: 12px
    }

    > svg {
      color: var(--jaffa-100);
      height: 16px;
      width: auto;
    }
  }
}