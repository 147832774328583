.button {
    all: unset;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    border-radius: 50px;
    border: 1px solid var(--black-60);
    width: 100%;
    padding: 10px;

    > .icon {
        height: 26px;
        width: auto;
    }

    > .label {
        flex-grow: 1;
        text-align: center;
        font-size: 18px;
        font-weight: var(--body-bold-font-weight);
    }
}