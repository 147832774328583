.container {
    width: 100%;
    height: 100%;
    background-color: var(--white-100);
    overflow-y: scroll;

    box-sizing: border-box;

    padding: 20px;
    font-size: 12px;

    > .button {
        position: absolute;
        top: 20px;
        right: 20px;

        svg {
            color: var(--flame-100);
        }
    }
}