.page {
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.label {
    font-weight: bold;
}