.header {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;

    > .title {
        font-size: 18px;
        font-weight: bold;
    }

    > .button {
        all: unset;
        font-size: 18px;
        font-weight: bold;
        color: var(--matisse-100);
    }
}

.infomation {
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid var(--black-60);
    border-radius: 12px;

    > .column {
        display: flex;
        flex-direction: column;

        padding-top: 10px;
        margin-bottom: 10px;

        > .label {
            font-size: 12px;
            font-weight: bold;
            color: var(--black-80);
            padding-left: 3px;
            padding-right: 3px;
        }

        input, select {
            all: unset;
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: 3px;
            padding-right: 3px;
            border-radius: 3px;

            &:not(:disabled) {
                background-color: var(--black-60);
            }
        }

        > .family {
            display: flex;
            align-items: center;
            gap: 16px;
    
            input {
                flex: 1
            };
        }
    }

    > .column + .column {
        border-top: 1px solid var(--black-60);
    }
}

.logout {
    padding-top: 40px;
    display: flex;
    justify-content: center;

    > .button {
        all: unset;
        font-size: 16px;
        font-weight: bold;
        color: var(--matisse-100);
        border-bottom: 1px solid var(--matisse-100);
    }
}