.container {
    box-sizing: border-box;
    width: calc(100% - 40px);

    background-color: var(--jaffa-60);
    border-radius: 12px;
    border: 3px solid;
    border-color: var(--jaffa-100);

    display: flex;
    flex-direction: column;
    overflow: hidden;

    > .header {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    
        > .title {
            display: flex;
            align-items: center;
    
            > span {
                color: var(--black-100);
                font-family: var(--header-2-font-family);
                font-size: var(--header-2-font-size);
                font-style: var(--header-2-font-style);
                font-weight: var(--header-2-font-weight);
                flex-grow: 1;
            }
    
            svg {
                height: 26px;
                width: auto;
                color: var(--black-80);
            }
        }
    }

    > .content {
        flex-grow: 1;
        background-color: var(--white-100);
        padding: 20px;
    
        display: flex;
        flex-direction: column;
        align-items: center;
    
        > .current {
            margin-bottom: 20px;
            font-family: var(--header-2-font-family);
            font-size: var(--header-2-font-size);
            font-style: var(--header-2-font-style);
            font-weight: var(--header-2-font-weight);
    
            > .text {
                color: var(--black-100);
            }
    
            > .amount {
                color: var(--jaffa-100);
            }
        }
    }
}

.benefits {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;

    overflow-y: scroll;

    > .item {
        all: unset;

        display: flex;
        align-items: center;

        border-radius: 12px;
        border: 1px solid;
        border-color: rgb(199, 199, 199);
        padding: 12px;
        box-sizing: border-box;
        width: 100%;

        > .rightArrow {
            width: 24px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            > svg {
                color: var(--black-80);
            }
        }

        > .label {
            flex: 1;
            display: flex;
            flex-direction: column;
            font-family: var(--header-2-font-family);
            font-size: var(--header-2-font-size);
            font-style: var(--header-2-font-style);

            > .title {
                display: flex;
                margin-bottom: 10px;

                > span {
                    font-weight: var(--header-2-font-weight);
                    color: var(--jaffa-100);
                    margin-right: 12px;
                }

                > .require {
                    display: flex;
                    align-items: center;

                    > span {
                        font-size: 12px;
                        font-weight: 700;
                        color: var(--black-80);
                    }

                    > svg {
                        color: var(--jaffa-100);
                        height: 17px;
                        width: auto;
                        padding-right: 2px;
                    }
                }
            }

            > .content {
                color: var(--black-100);
                font-weight: var(--header-2-font-weight);
                font-size: 16px;
            }
        }

        &:disabled {
            background-color: rgba(0, 0, 0, 0.1);

            > .label { > .title { > .require { > svg {
                color: var(--black-80);
            }}}}
        }
    }
}