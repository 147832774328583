.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.inButton {
    position: relative;
    width: 100%;

    > .label {
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        
        display: flex;
        align-items: center;

        padding-left: 10px;
        padding-right: 10px;
        font-family: var(--header-2-font-family);
        font-size: 15px;

        > .title {
            flex: 1;
            overflow: hidden;
            text-align: left;

            > .text {
                white-space: nowrap;
            }
        }

        > .percent {
            width: 42px;
            text-align: end;
        }
    }
}