.item {
    all: unset;
    width: 100%;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 2px solid var(--black-60);
    font-family: var(--header-2-font-family);
    font-style: var(--header-2-font-style);

    > .number {
        margin-right: 10px;
        font-weight: var(--header-2-font-weight);
        color: var(--jaffa-100);
        width: 50px;

        &.disabled {
            color: var(--black-80);
        }
    }

    > .label {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        color: var(--black-100);

        > .title {
            font-weight: var(--header-2-font-weight);
        }

        > .status {
            display: flex;
            font-size: 14px;
            color: var(--black-80);

            > .date {
                margin-left: auto;
            }
        }
    }

    > svg {
        margin-left: 10px;
        color: var(--black-80);
    }
}