.header {
    display: flex;
    flex-direction: column;
    font-family: var(--header-2-font-family);
    font-style: var(--header-2-font-style);
    width: 100%;
    gap: 6px;

    > .number {
        font-weight: var(--header-2-font-weight);
        color: var(--jaffa-100);
        font-size: 14px;
    }

    > .title {
        font-size: var(--header-2-font-size);
        font-weight: var(--header-2-font-weight);
        color: var(--black-100);
    }
}

.buttons {
    margin-top: 16px;
    align-items: center;
    display: flex;
    width: 100%;
}