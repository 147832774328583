.container {
    all: unset;
    width: 100%;
    box-sizing: border-box;

    background-color: var(--jaffa-60);
    border: 4px solid;
    border-color: var(--white-100);
    border-radius: 12px;
    box-shadow: var(--card-normal);
    display: flex;
    flex-direction: column;
    gap: 45px;
    padding: 12px;
}

.header {
    position: relative;

    > .text {
        color: var(--black-100);
        font-family: var(--header-2-font-family);
        font-size: var(--header-2-font-size);
        font-style: var(--header-2-font-style);
        font-weight: var(--header-2-font-weight);
    }

    > .button {
        background-color: var(--jaffa-100);
        border-radius: 60px;
        height: 27px;
        width: 27px;

        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;

        top: 0;
        right: 0;
        
        > span {
            color: var(--white-100);
            font-family: var(--numbering-font-family);
            font-size: var(--numbering-font-size);
            font-style: var(--numbering-font-style);
            font-weight: var(--numbering-font-weight);
        }
    }
}