.progress {
    position: relative;
    width: 100%;
    box-sizing: border-box;

    > .line {
        display: block;
        z-index: 10;
        position: absolute;
        top: 0;
        height: 100%;
        border-left: 2px dotted var(--black-80);
        
        > .label {
            left: -16px;
        }
    }

    .label {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: var(--black-80);

        top: -25px;

        &.labelLast {
            right: 0;
        }

        &.isCompleted {
            > svg, >span {
                color: var(--jaffa-100);
            }
        }
    }
}