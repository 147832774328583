@media screen and (min-width: 768px) {
    .container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--black-60);

        > .content {
            width: 480px;
            overflow: hidden;
            box-shadow: var(--card-normal);
        }
    };
};

.progress {
    position: absolute;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    z-index: 500;
    background-color: white;

    display: flex;
    align-items: center;
    justify-content: center;
}