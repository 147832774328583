.container {
    height: 100%;
    width: 100%;

    padding: 20px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 20px;

    background-color: var(--white-100);
}

.header {
    font-size: 18px;
    font-weight: bold;

    text-align: center;
    width: 100%;

    padding-top: 28px;
}

.input {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;

    input, select {
        all: unset;
        box-sizing: border-box;
        height: 50px;
        width: auto;

        border: 1px solid var(--flame-100);
        border-radius: 4px;

        display: flex;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
    }

    select {
        width: 120px;
    }

    > .family {
        display: flex;
        align-items: center;
        gap: 16px;

        input {
            flex: 1
        };
    }
}

.label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    font-weight: bold;

    > .required {
        color: rgb(231, 105, 105);
        font-size: 10px;
        font-weight: bold;
    }
}

.notes {
    font-size: 12px;
}

.button {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
    margin-top: 40px;

    > button {
        box-sizing: border-box;
        height: 59px;
        width: 156px;
        margin-left: auto;
    }
}