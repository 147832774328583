:root {
    --black-100: rgba(0, 0, 0, 1);
    --black-60: rgba(230, 230, 230, 1);
    --black-80: rgba(102, 102, 102, 1);
    --body-bold-font-family: "NotoSans CJK JP-Bold", Helvetica;
    --body-bold-font-size: 16px;
    --body-bold-font-style: normal;
    --body-bold-font-weight: 700;
    --body-bold-letter-spacing: 0px;
    --body-bold-line-height: 28.799999237060547px;
    --body-font-family: "NotoSans CJK JP-Regular", Helvetica;
    --body-font-size: 16px;
    --body-font-style: normal;
    --body-font-weight: 400;
    --body-letter-spacing: 0px;
    --body-line-height: 28.799999237060547px;
    --card-normal: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    --flame-100: rgba(234, 90, 70, 1);
    --flame-60: rgba(251, 222, 218, 1);
    --flame-80: rgba(242, 156, 144, 1);
    --header-1-font-family: "NotoSans CJK JP-Bold", Helvetica;
    --header-1-font-size: 26px;
    --header-1-font-style: normal;
    --header-1-font-weight: 700;
    --header-1-letter-spacing: 0px;
    --header-1-line-height: normal;
    --header-2-font-family: "NotoSans CJK JP-Bold", Helvetica;
    --header-2-font-size: 18px;
    --header-2-font-style: normal;
    --header-2-font-weight: 700;
    --header-2-letter-spacing: 0px;
    --header-2-line-height: normal;
    --jaffa-100: rgba(234, 146, 70, 1);
    --jaffa-60: rgba(253, 244, 237, 1);
    --jaffa-80: rgba(242, 190, 144, 1);
    --matisse-100: rgba(30, 92, 153, 1);
    --matisse-60: rgba(233, 239, 245, 1);
    --matisse-80: rgba(120, 157, 194, 1);
    --menu-2-font-family: "NotoSans CJK JP-Bold", Helvetica;
    --menu-2-font-size: 13px;
    --menu-2-font-style: normal;
    --menu-2-font-weight: 700;
    --menu-2-letter-spacing: 0.26px;
    --menu-2-line-height: 13px;
    --menu-font-family: "NotoSans CJK JP-Medium", Helvetica;
    --menu-font-size: 10px;
    --menu-font-style: normal;
    --menu-font-weight: 500;
    --menu-letter-spacing: 0.2px;
    --menu-line-height: 13px;
    --numbering-font-family: "Roboto-Bold", Helvetica;
    --numbering-font-size: 12px;
    --numbering-font-style: normal;
    --numbering-font-weight: 700;
    --numbering-letter-spacing: 0px;
    --numbering-line-height: normal;
    --radio-button-normal: ;
    --system-font-family: "Roboto-Medium", Helvetica;
    --system-font-size: 16px;
    --system-font-style: normal;
    --system-font-weight: 500;
    --system-letter-spacing: 0.01600000023841858px;
    --system-line-height: normal;
    --white-100: rgba(255, 255, 255, 1);
    --white-80: rgba(250, 250, 250, 1);
    --background-settings: rgb(255, 255, 255);
    --title-font-color: rgb(0, 0, 0);
    --title-font-size: 20px;
    --title-font-weight: 700;
    --button-font-color: rgb(70, 113, 206);
    --button-font-size: 18px;
    --button-font-weight: 700;
    --item-line-color: rgb(202, 202, 202);
    --button-export-background: rgb(30, 92, 153);
  }
  