.background {
    background-color: var(--jaffa-60);
    padding-top: 60px;
    height: 100%;
    box-sizing: border-box;
}

.container {
    box-sizing: border-box;
    height: 100%;
    align-items: center;
    align-self: stretch;
    background-color: var(--white-100);
    border-radius: 32px 32px 0px 0px;
    box-shadow: var(--card-normal);
    display: flex;
    flex-direction: column;
    padding: 24px;
}

.header {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: 100%;

    > .number {
        color: var(--flame-100);
        font-family: "Roboto", Helvetica;
        font-size: 16px;
        font-weight: 700;
    }

    > .title {
        color: var(--black-100);
        font-family: var(--header-2-font-family);
        font-size: var(--header-2-font-size);
        font-style: var(--header-2-font-style);
        font-weight: var(--header-2-font-weight);
        letter-spacing: var(--header-2-letter-spacing);
        line-height: var(--header-2-line-height);
    }

    > .description {
        align-self: stretch;
        color: var(--black-100);
        font-family: var(--body-font-family);
        font-size: var(--body-font-size);
        font-style: var(--body-font-style);
        font-weight: var(--body-font-weight);
        letter-spacing: var(--body-letter-spacing);
        line-height: var(--body-line-height);
    }

    > .example {
        align-self: stretch;
        color: var(--flame-100);
        font-family: var(--menu-2-font-family);
        font-size: var(--menu-2-font-size);
        font-style: var(--menu-2-font-style);
        font-weight: var(--menu-2-font-weight);
        letter-spacing: var(--menu-2-letter-spacing);
        line-height: var(--menu-2-line-height);
    }
}


.button {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;

    > button {
        box-sizing: border-box;
        height: 59px;
        width: 156px;
    }
}

.content {
    width: 100%;
    box-sizing: border-box;

    flex-grow: 1;

    margin-top: 20px;
    margin-bottom: 20px;
}