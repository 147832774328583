.button {
    all: unset;

    position: absolute;
    bottom: 100px;
    right: 20px;
    z-index: 200;

    background-color: var(--button-export-background);
    color: var(--white-100);
    box-shadow: var(--card-normal);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 86px;
    width: 86px;
    border-radius: 100%;

    > svg {
        height: 40px;
        width: auto;
    }

    > span {
        font-size: 13px;
        font-weight: bold;
    }
}

.loadingBackground {
    z-index: 200;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    > .message {
        color: var(--white-100);
        font-weight: bold;
    }
}