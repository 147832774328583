.container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-size: 12px;

    > .header {
        position: relative;
        z-index: 100;
        height: 64px;
        background-color: var(--flame-100);
        box-shadow: var(--card-normal);
        > img {
            position: absolute;
            left: 20px;
            bottom: 14px;
        }
    }

    > .banner {
        > img {
            width: 100%;
            height: auto;
            vertical-align:top;
        }
    }

    > .login {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: var(--white-80);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        > .title {
            font-size: 18px;
            font-weight: var(--body-bold-font-weight);
        }

        > .sociallogin {
            > .loginbuttons {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;
            }
        }
    }

    > .contact {
        background-color: var(--jaffa-60);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding-top: 40px;
        padding-bottom: 40px;

        > span {
            font-weight: var(--body-bold-font-weight);
        };
    }

    > .footer {
        font-size: 10px;
        color: var(--white-100);
        background-color: var(--black-100);

        display: flex;
        flex-direction: column;
        align-items: center;

        padding-top: 30px;
        padding-bottom: 60px;

        > .links {
            display: flex;
            align-items: center;
            gap: 40px;
        }

        > .copyright {
            margin-top: 30px;
            color: var(--black-80);
        }
    }
}

.card {
    background-color: var(--white-100);
    border: 1px solid var(--black-60);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;

    > .title {
        font-weight: var(--body-bold-font-weight);
        font-size: 16px;
    }

    > ul {
        margin: 0px;
        padding-left: 20px;
    }
}

.link {
    all: unset;
    display: inline;

    &.highlight {
        > span {
            color: var(--matisse-100);
            font-weight: bold;
        }
    }
}