.container {
  background-color: var(--white-100);
  display: flex;
  flex-direction: row;
  height: 56px;
  align-items: center;

  .buttons {
    margin-left: auto;
  }
}