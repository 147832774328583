.label {
    background-color: rgb(250, 249, 247);
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    gap: 4px;
    border-bottom: 2px solid var(--black-60);

    color: var(--black-80);
    font-family: var(--header-2-font-family);
    font-size: 14px;
    font-weight: var(--header-2-font-weight);

    > svg {
        height: 15px;
        width: auto;
    }
}