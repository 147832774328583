.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 12px;
    border: 1px solid var(--black-60);

    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 40px;
    padding-right: 40px;

    gap: 24px;

    > svg {
        color: var(--matisse-80);
        height: 48px;
        width: auto;
    }
}