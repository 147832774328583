.background {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--white-100);
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    > .title {
        color: var(--title-font-color);
        font-size: var(--title-font-size);
        font-weight: var(--title-font-weight);
    }

    > button {
        all: unset;
        color: var(--button-font-color);
        font-size: var(--button-font-size);
        font-weight: var(--button-font-weight);
        position: absolute;

        &.buttonBack {
            left: 0;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.buttonClose {
            right: 0;
        }
    }
}

.content {
    flex-grow: 1;
    overflow-y: scroll;
}