.container {
    background-color: var(--white-100);
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    img {
        width: 100%;
        height: auto;
    }
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;

    > .stepper {
        display: flex;
        justify-content: center;
        gap: 10px;
        > svg {
            height: 16px;
            width: auto;
    
            color: var(--flame-60);
    
            &.active {
                color: var(--flame-100);
            }
        }
    }

    > .texts {
        flex: 1;
        padding-top: 20px;
        display: flex;
        gap: 20px;
        flex-direction: column;

        > .title {
            font-weight: var(--body-bold-font-weight);
            font-size: 26px;
            text-align: center;
    
            .accent {
                color: var(--flame-100);
            }
        }
    
        > .description {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            text-align: center;
            gap: 4px;
        }
    }

    > .buttons {
        display: flex;
        align-items: center;
        align-self: stretch;
        margin-bottom: 15%;
        justify-content: space-between;
        width: 100%;
    
        > button {
            box-sizing: border-box;
            height: 59px;
            width: 156px;
        }
    }
}